.App {
  text-align: center;
}

.ft-black {
  color: #282525;
}

.ft-orange {
  color: #f68b48;
}

.ft-bg-black {
  background-color: #282525;
}

.ft-bg-orange {
  background-color: #f68b48;
}

.link:hover {
  color: #f68b48;
}

.parallax {
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.line {
  height: 1px;
  background-color: #f68b48;
}

.vline {
  width: 1px;
  background-color: #f68b48;
}

/* Modal */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1040;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  display: flex;
  align-items: center;
}

.modal {
  z-index: 100;
  background: #fff;
  position: relative;
  margin: auto;
  border-radius: 5px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-close-button {
  font-size: 1.4rem;
  font-weight: 700;
  color: #000;
  cursor: pointer;
  border: none;
  background: transparent;
}

/* /Modal */
